








































































import { Component } from 'vue-property-decorator';
import _ from 'lodash';

import {
  InputFieldCpt,
  InputDateCpt,
  InputSelectCpt,
  InputRadioCpt,
  SpinnerCpt,
  NoReporterOrganisationMessageCpt,
} from '@/core/components';
import { BasicUserAccountDetailsModel, ReporterProfileLookupModel, ReporterRolePermissionsModel } from '@/core/webapi';
import { ApiService } from '@/core/services';
import { StudyTypes } from '@/core/constants';
import { InputOrganisationsCpt, InputOrganisationStudiesCpt } from '@/modules/admin/users/components';
import AppVue from '@/AppVue.vue';

@Component({
  components: {
    InputFieldCpt,
    InputDateCpt,
    InputSelectCpt,
    InputRadioCpt,
    InputOrganisationsCpt,
    InputOrganisationStudiesCpt,
    SpinnerCpt,
    NoReporterOrganisationMessageCpt,
  },
})
export default class ReporterProfileView extends AppVue {
  lookups = {} as ReporterProfileLookupModel;
  basicAccountModel = new BasicUserAccountDetailsModel({ modelState: {} } as any);
  model = new ReporterRolePermissionsModel({ modelState: {} } as any);
  loading = true;
  eliteId = StudyTypes.Elite;
  communityId = StudyTypes.Community;

  get showReporterOrgs() {
    if (!this.isStudyAdmin) {
      return true;
    }

    return !!this.model.reporterOrganisations?.length;
  }

  async created() {
    try {
      this.lookups = (await ApiService.users().getReporterProfileLookupData()).data;
      await this.initializeModels();
    } finally {
      this.loading = false;
    }
  }

  private async initializeModels() {
    const basicAccountModel = (await ApiService.users().getBasicAccountDetails(this.currentUserId!)).data;
    _.extend(this.basicAccountModel, basicAccountModel);
    const model = (await ApiService.users().getReporterRolePermissions(this.currentUserId!)).data;
    _.extend(this.model, model);
  }
}
