import { render, staticRenderFns } from "./ReporterAccessRequestsGridView.vue?vue&type=template&id=c3b18f22&scoped=true&"
import script from "./ReporterAccessRequestsGridView.vue?vue&type=script&lang=ts&"
export * from "./ReporterAccessRequestsGridView.vue?vue&type=script&lang=ts&"
import style0 from "./ReporterAccessRequestsGridView.vue?vue&type=style&index=0&id=c3b18f22&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3b18f22",
  null
  
)

export default component.exports