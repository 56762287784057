



















import { Component, Vue, Prop } from 'vue-property-decorator';
import _ from 'lodash';

import { AutoCompleteItem } from '@/core/models';
import ArchiverFinderCpt from './ArchiverFinderCpt.vue';

@Component({
  components: {
    ArchiverFinderCpt,
  },
})
export default class ArchiverFinderDialogCpt extends Vue {
  @Prop() onSelected?: (foundItem: AutoCompleteItem) => void;
  @Prop({ default: false }) showDialog: boolean;
  @Prop({ default: null }) roleId?: number;

  get isOpen() {
    return this.showDialog;
  }

  get title() {
    return this.$t('lbl.selectAdmin');
  }

  onSelectedInternal(foundItem: AutoCompleteItem) {
    if (this.onSelected) {
      this.onSelected(foundItem);
    }

    this.close();
  }

  close() {
    this.$emit('close');
  }
}
