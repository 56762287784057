



















































































































































import { Component } from 'vue-property-decorator';

import AppVue from '@/AppVue.vue';
import { DownloadProvider, ApiService } from '@/core/services';
import { OrganisationModel, StudyModel } from '@/core/webapi';

@Component
export default class ExportView extends AppVue {
  // NOTE: This view should probably be split into multiple components - one per user role

  internalStudies: StudyModel[] | null = null;
  organisations: OrganisationModel[] | null = null;
  selectedOrganisationId: number | null = null; // For MA's
  selectedStudyId: number | null = null; // For reporters and analysts
  exportType: string | null = null;
  isExportTypeSet = false;

  get studies() {
    if (this.isReporter || this.isAnalyst) {
      return this.internalStudies ?? [];
    } else if (this.isMedicalAdmin && this.selectedOrganisationId) {
      return this.organisations?.find(p => p.id === this.selectedOrganisationId)?.studies ?? [];
    }

    return [];
  }

  get analystOrganisations() {
    if (!this.selectedStudyId) {
      return [];
    }

    return this.internalStudies?.find(p => p.id === this.selectedStudyId)?.organisations ?? [];
  }

  async created() {
    if (this.isReporter) {
      this.internalStudies = (
        await ApiService.studies().getReporterExportableStudiesLookupData(this.currentOrganisationId!)
      ).data;
    } else if (this.isAnalyst) {
      this.internalStudies = (await ApiService.studies().getAnalystExportableStudiesLookupData()).data;
    } else {
      this.internalStudies = [];
    }

    if (this.isMedicalAdmin) {
      this.organisations = (await ApiService.organisations().getMedicalAdminExportableOrganisationsLookupData()).data;
      this.preselectOrganisationId();
    }

    this.preselectStudyId();
  }

  preselectOrganisationId() {
    this.selectedOrganisationId = this.organisations && this.organisations.length > 0 ? this.organisations[0].id : null;
  }

  preselectStudyId() {
    this.selectedStudyId = this.studies && this.studies.length > 0 ? this.studies[0].id : null;
  }

  onOrganisationChange() {
    this.preselectStudyId();
  }

  exportCsv(type: string) {
    this.exportType = type;
    this.isExportTypeSet = true;
  }

  async confirmCsvExport() {
    if (this.exportType) {
      await ApiService.export().acceptCsvExportDisclaimer(this.exportType);
      await (this as any)[this.exportType]();
    }

    this.exportType = null;
    this.isExportTypeSet = false;
  }

  async cancelCsvExport() {
    this.exportType = null;
    this.isExportTypeSet = false;
  }

  async reporterInjuries() {
    const data = (await ApiService.export().reporterInjuriesCsv(this.currentOrganisationId, this.selectedStudyId!))
      .data;
    DownloadProvider.downloadAsCsv(data, `World Rugby ISS - Injuries - ${this.getOrganisationAndStudyName()}`);
  }

  async reporterIllnesses() {
    const data = (await ApiService.export().reporterIllnessesCsv(this.currentOrganisationId, this.selectedStudyId!))
      .data;
    DownloadProvider.downloadAsCsv(data, `World Rugby ISS - Illnesses - ${this.getOrganisationAndStudyName()}`);
  }

  async reporterExposures() {
    const data = (await ApiService.export().reporterExposuresCsv(this.currentOrganisationId, this.selectedStudyId!))
      .data;
    DownloadProvider.downloadAsCsv(data, `World Rugby ISS - Exposures - ${this.getOrganisationAndStudyName()}`);
  }

  async analystInjuries() {
    const data = (await ApiService.export().analystInjuriesCsv(this.selectedStudyId!, this.selectedOrganisationId!))
      .data;
    DownloadProvider.downloadAsCsv(data, `World Rugby ISS - Injuries - ${this.getOrganisationAndStudyName()}`);
  }

  async analystIllnesses() {
    const data = (await ApiService.export().analystIllnessesCsv(this.selectedStudyId!, this.selectedOrganisationId!))
      .data;
    DownloadProvider.downloadAsCsv(data, `World Rugby ISS - Illnesses - ${this.getOrganisationAndStudyName()}`);
  }

  async analystExposures() {
    const data = (await ApiService.export().analystExposuresCsv(this.selectedStudyId!, this.selectedOrganisationId!))
      .data;
    DownloadProvider.downloadAsCsv(data, `World Rugby ISS - Exposures - ${this.getOrganisationAndStudyName()}`);
  }

  async analystPlayers() {
    const data = (await ApiService.export().analystPlayersCsv(this.selectedStudyId!, this.selectedOrganisationId!))
      .data;
    DownloadProvider.downloadAsCsv(data, `World Rugby ISS - Players - ${this.getOrganisationAndStudyName()}`);
  }

  async medicalAdminOrganisationStudy() {
    const data = (
      await ApiService.export().medicalAdminOrganisationStudyCsv(this.selectedOrganisationId!, this.selectedStudyId!)
    ).data;
    DownloadProvider.downloadAsCsv(data, `World Rugby ISS - ${this.getOrganisationAndStudyName()}`);
  }

  getOrganisationAndStudyName() {
    let name = '';

    if (this.selectedOrganisationId) {
      name += this.organisations?.find(p => p.id === this.selectedOrganisationId)?.name ?? '';
      if (!!name) {
        name += ' - ';
      }
    }

    if (this.selectedStudyId) {
      name += this.studies?.find(p => p.id === this.selectedStudyId)?.name ?? '';
    }

    if (this.isAnalyst) {
      if (this.selectedOrganisationId) {
        name += ' - ' + this.analystOrganisations?.find(p => p.id === this.selectedOrganisationId)?.name ?? '';
      }
    }

    return name;
  }

  async all() {
    if (this.canAccessInjuryAndIllnessData) {
      this.reporterInjuries();
      this.reporterIllnesses();
    }
    if (this.canAccessExposureData) {
      this.reporterExposures();
    }
  }
}
