






































































































































import { Component, Prop } from 'vue-property-decorator';
import _ from 'lodash';

import { AutoCompleteItem } from '@/core/models';
import { ApiService } from '@/core/services';
import {
  BaseFormComponent,
  InputFieldCpt,
  InputTextareaCpt,
  InputSelectCpt,
  InputDateCpt,
  ValidationHintCpt,
  SpinnerCpt,
} from '@/core/components';
import { StudyLookupModel, StudyModel } from '@/core/webapi';
import ArchiverFinderDialogCpt from './ArchiverFinderDialogCpt.vue';
import { GameFormats, StudyTypes } from '@/core/constants';

@Component({
  components: {
    InputFieldCpt,
    InputTextareaCpt,
    InputSelectCpt,
    InputDateCpt,
    ValidationHintCpt,
    ArchiverFinderDialogCpt,
    SpinnerCpt,
  },
})
export default class StudyFormCpt extends BaseFormComponent {
  @Prop() studyId?: number;

  lookups = {} as StudyLookupModel;
  model = new StudyModel({ modelState: {} } as any);
  showDialog = false;
  adminRoleId = 2;
  loading = true;

  get gameFormats() {
    if (!this.lookups?.gameFormats || !this.model?.studyTypeId) {
      return [];
    }

    // Elite studies can only be 15's or 7's
    // Community studies can be any type, so "multiple" is the only option
    return this.model.studyTypeId === StudyTypes.Elite
      ? this.lookups.gameFormats.filter(x => x.id < GameFormats.Multiple)
      : this.lookups.gameFormats.filter(x => x.id === GameFormats.Multiple);
  }

  async created() {
    try {
      await this.initializeModel();
      await this.initializeLookups();
    } finally {
      this.loading = false;
    }
  }

  async onSubmitForm() {
    this.model = this.studyId
      ? (await ApiService.studies().updateStudy(this.model).handleValidationErrors(this.model)).data
      : (await ApiService.studies().createStudy(this.model).handleValidationErrors(this.model)).data;

    this.$notify({
      title: this.$t('msg.success') as string,
      message: this.$t('lbl.itemSaved') as string,
      type: 'success',
    });

    if (!this.studyId) {
      this.$router.push(`/admin/studies/${this.model.id}/edit`);
    }
  }

  initializeArchiving() {
    this.showDialog = true;
  }

  async onArchiverSelected(foundItem: AutoCompleteItem) {
    if (!confirm(this.$t('confirm.studyArchival') as string)) {
      return;
    }

    await ApiService.archive().initializeArchiving(this.studyId as number, foundItem.id);

    this.$notify({
      title: this.$t('msg.success') as string,
      message: this.$t('msg.archivalInitiated') as string,
      type: 'success',
    });

    await this.initializeModel();
  }

  onStudyTypeChange() {
    (this.model.gameFormatId as any) = this.model.studyTypeId === StudyTypes.Elite ? null : GameFormats.Multiple;
  }

  closeDialog() {
    this.showDialog = false;
  }

  async confirmArchival() {
    if (!confirm(this.$t('confirm.studyArchival') as string)) {
      return;
    }

    await ApiService.archive().confirmArchiving(this.studyId as number);

    this.$notify({
      title: this.$t('msg.success') as string,
      message: this.$t('msg.archivalConfirmed') as string,
      type: 'success',
    });

    await this.initializeModel();
  }

  private async initializeModel() {
    if (this.studyId) {
      const model = (await ApiService.studies().getStudy(this.studyId)).data;
      _.extend(this.model, model);
    }
  }

  private async initializeLookups() {
    this.lookups = (await ApiService.studies().getManageStudyFormLookupData()).data;
  }
}
