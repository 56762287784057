















































































































































































































import { Component } from 'vue-property-decorator';
import _ from 'lodash';

import {
  BaseFormComponent,
  InputFieldCpt,
  ValidationSummaryCpt,
  ValidationHintCpt,
  SpinnerCpt,
} from '@/core/components';
import { BasicUserAccountDetailsModel, UserAccountLookupModel } from '@/core/webapi';
import { ApiService } from '@/core/services';
import {
  ManageStudyAdminFormCpt,
  ManageReporterFormCpt,
  ManageAnalystFormCpt,
  ManageMedicalAdminFormCpt,
} from '@/modules/admin/users/components';
import { UserRoles } from '@/core/constants';
import { store } from '@/core/store';

@Component({
  components: {
    InputFieldCpt,
    ValidationSummaryCpt,
    ValidationHintCpt,
    SpinnerCpt,
    ManageStudyAdminFormCpt,
    ManageReporterFormCpt,
    ManageAnalystFormCpt,
    ManageMedicalAdminFormCpt,
  },
})
export default class UserFormView extends BaseFormComponent {
  get userId() {
    return +this.$route.params.userId;
  }

  get showRolesPermissionsTabs() {
    return this.isAdmin && this.getComplexRolesIds().length > 0;
  }

  lookups = {} as UserAccountLookupModel;
  roleLookup = UserRoles;
  model = new BasicUserAccountDetailsModel({ modelState: {} } as any);
  loading = true;
  tab = '';

  roles = {
    isAdmin: false,
    isStudyAdmin: false,
    isReporter: false,
    isAnalyst: false,
    isMedicalAdmin: false,
  };

  get disableForStudyAdmin() {
    return this.isStudyAdmin && !!this.model.id;
  }

  async created() {
    try {
      this.lookups = (await ApiService.users().getManageUserFormLookupData()).data;
      await this.initializeModel();
    } finally {
      this.loading = false;
    }
  }

  async onSubmitForm() {
    try {
      this.userId
        ? await ApiService.users().updateAccount(this.model).handleValidationErrors(this.model)
        : await ApiService.users().createAccount(this.model).handleValidationErrors(this.model);

      this.$notify({
        title: this.$t('msg.success') as string,
        message: this.$t('msg.userSaved') as string,
        type: 'success',
      });

      this.$router.push('/admin/users');
    } finally {
      // Have to do this re-mapping to break the reference which then triggers "Access from" and "Access to" validation
      (this.model as any).modelState = { ...(this.model as any).modelState };
    }
  }

  async onRoleClick(roleId: number, makeRoleActive: boolean) {
    if (this.userId === this.userContext.userId && roleId === UserRoles.Admin) {
      this.$notify({
        title: this.$t('msg.error') as string,
        message: this.$t('msg.cantRemoveOwnAdminRole') as string,
        type: 'error',
      });
      return;
    }

    const confirmMessage = makeRoleActive ? 'confirm.addUserToRole' : 'confirm.removeUserFromRole';
    if (!confirm(this.$t(confirmMessage) as string)) {
      return;
    }

    (this.roles as any)[`is${UserRoles[roleId]}`] = makeRoleActive;

    if (makeRoleActive) {
      await ApiService.users().addUserToRole(this.userId, roleId);
      this.$notify({
        title: this.$t('msg.success') as string,
        message: this.$t('msg.userAddedToRole') as string,
        type: 'success',
      });
    } else {
      await ApiService.users().removeUserFromRole(this.userId, roleId);
      this.$notify({
        title: this.$t('msg.success') as string,
        message: this.$t('msg.userRemovedFromRole') as string,
        type: 'success',
      });
    }

    await this.initializeModel();

    if (this.userId === this.userContext.userId) {
      await store.dispatch('setUserContext');
    }
  }

  private getComplexRolesIds() {
    const lookupRoles = this.lookups.roles ?? [];
    const userRoleIds = this.model?.roleIds ?? [];

    // Admin role is currently the only one that doesn't have extra settings
    // like org or study id so we don't want it here. Also, lookup roles come
    // ordered by name from the backend so that's why we're mapping from the
    // lookups instead of just using `userRoleIds`.
    return lookupRoles.filter(p => userRoleIds.includes(p.id) && p.id !== UserRoles.Admin).map(p => p.id);
  }

  private async initializeModel() {
    if (this.userId) {
      const model = (await ApiService.users().getBasicAccountDetails(this.userId)).data;
      _.extend(this.model, model);

      const roleIds = model.roleIds ?? [];
      this.roles.isAdmin = roleIds.includes(UserRoles.Admin);
      this.roles.isAnalyst = roleIds.includes(UserRoles.Analyst);
      this.roles.isMedicalAdmin = roleIds.includes(UserRoles.MedicalAdmin);
      this.roles.isReporter = roleIds.includes(UserRoles.Reporter);
      this.roles.isStudyAdmin = roleIds.includes(UserRoles.StudyAdmin);

      const complexRoleIds = this.getComplexRolesIds();
      if (complexRoleIds.length) {
        this.tab = this.getTabName(complexRoleIds[0]);
      }
    } else {
      if (this.lookups.roles!.length === 1) {
        this.model.roleIds = [this.lookups.roles![0].id];
      }
    }
  }

  private getTabName(roleId: number) {
    const roleName = UserRoles[roleId];
    return roleName.charAt(0).toLowerCase() + roleName.slice(1);
  }
}
