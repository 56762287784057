











































import { Component } from 'vue-property-decorator';

import AppVue from '@/AppVue.vue';
import { ApiService } from '@/core/services';
import { AnalystDashboardItem } from '@/core/webapi';
import { QcStatus } from '@/core/constants';
import { SpinnerCpt } from '@/core/components';

@Component({
  components: {
    SpinnerCpt,
  },
})
export default class AnalystDashboardView extends AppVue {
  models = [] as AnalystDashboardItem[];
  loading = true;

  async created() {
    try {
      this.models = (await ApiService.dashboard().getAnalystDashboard()).data;
    } finally {
      this.loading = false;
    }
  }

  getUrl(model: AnalystDashboardItem) {
    const query = `organisationId=${model.organisationId}&studyId=${model.studyId}&qcStatusId=${QcStatus.AwaitingAnalyst}`;

    switch (model.reportType) {
      case 'Elite Injuries':
        return `/injuries?${query}`;
      case 'Community Injuries':
        return `/c/injuries?${query}`;
      case 'Elite Illnesses':
        return `/illnesses?${query}`;
      case 'Elite Exposures':
        return `/exposures?${query}`;
      case 'Community Match Exposures':
        return `/c/match-exposures?${query}`;
      case 'Community Training Exposures':
        return `/c/training-exposures?${query}`;
      default:
        break;
    }
  }
}
