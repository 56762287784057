















import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';

import { ApiService } from '@/core/services';
import { AuditLogEventModel } from '@/core/webapi';

@Component
export default class UserEventLogView extends Vue {
  eventLog: AuditLogEventModel[] = [];

  async created() {
    const userId = parseInt(this.$route.params.userId, 10);
    this.eventLog = (await ApiService.users().getUserAuditLog(userId)).data;
  }

  formatTime(time: any) {
    return moment(time).format('YYYY-MM-DD - HH:mm:ss');
  }
}
