










import { Component, Vue, Prop } from 'vue-property-decorator';
import _ from 'lodash';

import { UserCollectionModel } from '@/core/webapi';
import { ApiService } from '@/core/services';
import { AutoCompleteItem } from '@/core/models';
import { BaseFinderCpt } from '@/core/components';

@Component({
  components: {
    BaseFinderCpt,
  },
})
export default class ArchiverFinderCpt extends Vue {
  @Prop() onSelected?: (foundItem: AutoCompleteItem) => void;
  @Prop({ default: false }) autofocus: boolean;
  @Prop({ default: null }) roleId?: number;

  get noResultsText() {
    return this.$t('lbl.noMatchingUsersFound');
  }

  async findMethod(search: string) {
    return (await ApiService.users().filterUsersForArchival({ search } as any)).data;
  }

  formatResultList(items: UserCollectionModel[]) {
    return _.map(items, item => {
      return {
        id: item.id,
        value: `${item.firstName} ${item.lastName} (${item.email})`,
      };
    });
  }
}
