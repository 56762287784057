
































import { Component, Vue } from 'vue-property-decorator';

import AppVue from '@/AppVue.vue';

@Component
export default class Admin extends AppVue {}
