





























































































import { Component } from 'vue-property-decorator';
import _ from 'lodash';

import {
  BaseFormComponent,
  InputFieldCpt,
  InputDateCpt,
  InputSelectCpt,
  InputRadioCpt,
  ValidationSummaryCpt,
  ValidationHintCpt,
  SpinnerCpt,
} from '@/core/components';
import { PlayerLookupModel, PlayerModel } from '@/core/webapi';
import { ApiService, ReportLookupService } from '@/core/services';
import { GameFormats, StudyTypes } from '@/core/constants';

@Component({
  components: {
    InputFieldCpt,
    InputDateCpt,
    InputSelectCpt,
    InputRadioCpt,
    ValidationSummaryCpt,
    ValidationHintCpt,
    SpinnerCpt,
  },
})
export default class UserFormView extends BaseFormComponent {
  get playerId() {
    return +this.$route.params.playerId;
  }

  lookups = {} as PlayerLookupModel;
  model = new PlayerModel({ modelState: {} } as any);
  loading = true;

  get playingPositions15s() {
    if (!this.lookups || !this.lookups.playingPositions) {
      return [];
    }

    return _.filter(this.lookups.playingPositions, position => {
      return _.includes(position.gameFormatIds, GameFormats.Fifteens);
    });
  }

  get playingPositions7s() {
    if (!this.lookups || !this.lookups.playingPositions) {
      return [];
    }

    return _.filter(this.lookups.playingPositions, position => {
      return _.includes(position.gameFormatIds, GameFormats.Sevens);
    });
  }

  get studies() {
    return ReportLookupService.filterOrgStudies(this.lookups, this.model.playerOrganisationId, StudyTypes.Elite);
  }

  async created() {
    try {
      this.lookups = (await ApiService.players().getManagePlayerFormLookupData(this.currentOrganisationId)).data;
      await this.initializeModel();
    } finally {
      this.loading = false;
    }
  }

  async onSubmitForm() {
    try {
      this.playerId
        ? await ApiService.players().updatePlayer(this.model).handleValidationErrors(this.model)
        : await ApiService.players().createPlayer(this.model).handleValidationErrors(this.model);

      this.$notify({
        title: this.$t('msg.success') as string,
        message: this.$t('msg.userSaved') as string,
        type: 'success',
      });

      this.$router.push('/players');
    } finally {
      // Have to do this re-mapping to break the reference which then triggers "Access from" and "Access to" validation
      (this.model as any).modelState = { ...(this.model as any).modelState };
    }
  }

  private async initializeModel() {
    if (this.playerId) {
      const model = (await ApiService.players().getPlayer(this.playerId)).data;
      _.extend(this.model, model);
    } else {
      if (this.lookups.organisations!.length === 1) {
        this.model.playerOrganisationId = this.currentOrganisationId as any;
      }
      if (this.studies!.length === 1) {
        this.model.playerStudyId = this.studies[0].id;
      }
    }
  }

  onOrganisationChange() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete this.model.playerStudyId;
  }
}
