






import { Component } from 'vue-property-decorator';
import _ from 'lodash';

import AppVue from '@/AppVue.vue';
import ReporterAccessRequestCpt from '@/modules/access-requests/components/ReporterAccessRequestCpt.vue';

@Component({
  components: {
    ReporterAccessRequestCpt,
  },
})
export default class ReporterAccessRequestReviewView extends AppVue {}
