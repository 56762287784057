









import { Component, Vue } from 'vue-property-decorator';
import { StudyFormCpt, StudyOrganisationsCpt } from './components';

@Component({
  components: {
    StudyFormCpt,
    StudyOrganisationsCpt,
  },
})
export default class StudyFormView extends Vue {
  get studyId() {
    return this.$route.params.studyId;
  }
}
