




































































































import { Component } from 'vue-property-decorator';
import _ from 'lodash';

import AppVue from '@/AppVue.vue';
import { UserCollectionModel } from '@/core/webapi';
import { ApiService } from '@/core/services';
import { GridFactory, GridModel, GridSearchCpt, GridPagerCpt } from '@/core/components/grid';

@Component({
  components: {
    GridSearchCpt,
    GridPagerCpt,
  },
})
export default class UsersGridView extends AppVue {
  grid = {} as GridModel<UserCollectionModel>;

  async created() {
    this.grid = GridFactory.create({
      filterMethod: filter => ApiService.users().filterUsers(filter),
      routeName: 'adminUserGrid',
    });
  }

  formatList(roles: string[]) {
    return roles.map(p => _.startCase(p)).join(', ') || '-';
  }

  resetMfa(userId: number) {
    if (!confirm(this.$t('confirm.mfaReset') as string)) {
      return;
    }

    ApiService.users()
      .resetMfa(userId)
      .then(() => {
        this.$notify({
          title: this.$t('msg.success') as string,
          message: this.$t('msg.mfaSuccessfullyReset') as string,
          type: 'success',
        });
      })
      .catch(reason => {
        const message = reason.response.data.modelState?.['']?.[0];
        if (message) {
          this.$notify({
            title: this.$t('msg.error') as string,
            message: message,
            type: 'error',
          });
        }
      });
  }
}
