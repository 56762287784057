









import { Component, Vue } from 'vue-property-decorator';
import { OrganisationFormCpt, OrganisationStudiesCpt } from './components';

@Component({
  components: {
    OrganisationFormCpt,
    OrganisationStudiesCpt,
  },
})
export default class OrganisationFormView extends Vue {
  get organisationId() {
    return this.$route.params.organisationId;
  }
}
