




































import { Component, Prop } from 'vue-property-decorator';
import _ from 'lodash';

import { ApiService } from '@/core/services';
import { BaseFormComponent, InputFieldCpt, InputSelectCpt, ValidationHintCpt, SpinnerCpt } from '@/core/components';
import { OrganisationLookupModel, OrganisationModel } from '@/core/webapi';

@Component({
  components: {
    InputFieldCpt,
    InputSelectCpt,
    ValidationHintCpt,
    SpinnerCpt,
  },
})
export default class OrganisationFormCpt extends BaseFormComponent {
  @Prop() organisationId?: number;

  lookups = {} as OrganisationLookupModel;
  model = new OrganisationModel({ studies: [], modelState: {} } as any);
  loading = true;

  get disableForm() {
    return !!this.organisationId && this.isStudyAdmin;
  }

  async created() {
    try {
      await this.initializeModel();
      await this.initializeLookups();
    } finally {
      this.loading = false;
    }
  }

  async onSubmitForm() {
    this.model = this.organisationId
      ? (await ApiService.organisations().updateOrganisation(this.model).handleValidationErrors(this.model)).data
      : (await ApiService.organisations().createOrganisation(this.model).handleValidationErrors(this.model)).data;

    this.$notify({
      title: this.$t('msg.success') as string,
      message: this.$t('lbl.itemSaved') as string,
      type: 'success',
    });

    if (!this.organisationId) {
      this.$router.push(`/admin/organisations/${this.model.id}/edit`);
    }
  }

  private async initializeModel() {
    if (this.organisationId) {
      const model = (await ApiService.organisations().getOrganisation(this.organisationId)).data;
      _.extend(this.model, model);
    }
  }

  private async initializeLookups() {
    this.lookups = (await ApiService.organisations().getManageOrganisationFormLookupData()).data;
  }
}
