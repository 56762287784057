




















































































import { Component } from 'vue-property-decorator';
import _ from 'lodash';

import AppVue from '@/AppVue.vue';
import { ApiService } from '@/core/services';
import { StatusTagCpt } from '@/core/components';
import { GridFactory, GridModel, GridFilter, GridSearchCpt, GridPagerCpt } from '@/core/components/grid';
import { ReporterAccessRequestCollectionModel } from '@/core/webapi';
import { ReporterRequestStatus } from '@/core/constants';

@Component({
  components: {
    GridSearchCpt,
    GridPagerCpt,
    StatusTagCpt,
  },
})
export default class StudiesGridView extends AppVue {
  grid = {} as GridModel<ReporterAccessRequestCollectionModel>;

  reporterRequestStatus = ReporterRequestStatus;

  async created() {
    this.grid = GridFactory.create({
      filterMethod: filter => ApiService.reporterAccess().filterReporterRequests(filter),
      routeName: 'adminReporterAccessRequests',
      defaultFilterOverrides: new GridFilter(),
    });
  }

  getOrgRequestQueryParams(request: ReporterAccessRequestCollectionModel) {
    return `?organisationId=${request.organisations![0].organisationId}&reporterUserId=${request.reporterUserId}`;
  }
}
